<template>
  <b-card class="shadow mb-4" no-body
          :id="collapse_state?record_type+'-records':'not-'+record_type+'-records'">
    <b-card-header v-b-toggle="'records-'+record_type+'-collapse'" class="collapse-header">
      <b-badge v-if="records" variant="secondary"
               class="mr-2 align-text-top" pill>{{ records.length }}
      </b-badge>
      <h5 class="d-inline">{{ record_type }} {{ $tc('system.record', 2) }}</h5>
      <netvs-icon class="collapse-icon" icon="collapse"></netvs-icon>
    </b-card-header>
    <b-card-body body-class="p-0">
      <b-collapse :id="'records-'+record_type+'-collapse'" :visible="collapse_state" @input="sync_collapse">
        <FilterInput v-model="filter" class="mx-3"/>
        <Paginator :current-page="current_page" :per-page="per_page" @current-page="current_page = $event"
                   @per-page="per_page = $event" :total-rows="filtered_and_sorted_records.length"
                   v-if="loaded && filtered_and_sorted_records.length > 0"/>

        <b-table-simple responsive class="table b-table" v-if="collapse_state">
          <b-thead>
            <b-tr>
              <b-th>
                <!--b-checkbox :indeterminate="toggleAllIsUnkn(record_type)" v-model="toggle_all[record_type]" @input="toggleAll(record_type)"
                            :unchecked-value="false"></b-checkbox!-->
                <div class="text-center">
                              <span :id="record_type + '_bulk'">
                              <b-button :disabled="!canEnableBulkAction(record_type)"
                                        @click="bulkDelete(record_type)" variant="outline-danger">
                                <netvs-icon icon="delete"></netvs-icon>
                              </b-button>
                              </span>
                </div>
                <b-tooltip :target="record_type+'_bulk'" triggers="hover"
                           variant="danger" placement="right">
                  {{ $t('components.dns_record_type_card.delete_selected_records') }}
                </b-tooltip>
              </b-th>
              <b-th class="record-table-header"
                    :aria-sort="(sort_by === 'fqdn')?sort_dir:'none'"
                    @click="changeSort('fqdn')">
                <div>{{ $t('system.fqdn') }}</div>
              </b-th>
              <b-th class="record-table-header">{{ $t('system.more_information') }}</b-th>
              <b-th class="record-table-header"
                    :aria-sort="(sort_by === 'fqdn_description')?sort_dir:'none'"
                    @click="changeSort('fqdn_description')">{{ $t('system.fqdn_description') }}
              </b-th>
              <b-th class="record-table-header"
                    :aria-sort="(sort_by === 'data')?sort_dir:'none'"
                    @click="changeSort('data')">{{ $t('system.record_data') }}
              </b-th>
              <b-th class="record-table-header"
                    :aria-sort="(sort_by === 'ttl')?sort_dir:'none'"
                    @click="changeSort('ttl')">
                {{ $t('system.ttl') }}
              </b-th>
              <b-th>
                <b-btn-group class="d-flex" role="group">
                  <b-button variant="outline-success"
                            :id="'button-create-record-' + record_type" @click="createRecord(record_type)"
                            v-if="user_possible_record_types.includes(record_type)">
                    <netvs-icon icon="create"></netvs-icon>
                  </b-button>
                  <b-button variant="outline-primary"
                            :id="'button-export-record-' + record_type" @click="exportCSV()">
                    <netvs-icon icon="export"></netvs-icon>
                  </b-button>
                </b-btn-group>
                <b-tooltip :target="'button-create-record-' + record_type" triggers="hover"
                           variant="success" placement="left">
                  {{ $t('components.dns_record_type_card.create_new_record') }}
                </b-tooltip>
                <b-tooltip :target="'button-export-record-' + record_type" triggers="hover"
                           variant="primary" placement="bottom">
                  {{ $t('components.dns_record_type_card.csv_export') }}
                </b-tooltip>
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <!-- eslint-disable vue/no-v-for-template-key !-->
            <template
                v-for="(item, item_index) in fs_sliced_records">
              <template
                  v-if="record_type === 'A' && sort_by === 'data'  && !filter && item.data in upperIfAsc() && ((item_index-1 < 0 && fs_sliced_records.length !== 1) || fs_sliced_records[item_index-1].data !== item.data)">
                <BCDFreeAddressBlock :key="'block_upper_' + item.data + '_' + block_index"
                                     v-for="(block,block_index) in reverseIfDesc(upperIfAsc()[item.data].containing_blocks)"
                                     :create_item_func="createRecord" :block="block"
                                     :item="item" :index="block_index"></BCDFreeAddressBlock>
              </template>
              <b-tr :key="item.fqdn + '_' + item.data">
                <b-td>
                  <b-checkbox class="text-center"
                              v-model="records_checked[record_type + '_' + item.fqdn + '_' + item.data]"></b-checkbox>
                </b-td>
                <b-td>
                  {{ item.fqdn }}
                  <b-badge v-if="item.host_is_nws" variant="primary"
                           :href="$store.state.sysinfo.netdb_admin_base + `/~netadmin/natvs/user/wrapper.cgi/${bcd.name}/0/${bcd.name}/0/nat_index.html`"
                           :title="$t('components.dns_record_type_card.host_has_natvs_entry')">{{ $t('system.natvs') }}
                  </b-badge>
                </b-td>
                <b-td>
                  <RecordInfo :item="item"></RecordInfo>
                </b-td>
                <b-td>
                  <span class="fqdn-description">{{ item.fqdn_description }}</span>
                </b-td>
                <b-td>
                  <RRDataView :item="item" :subnets="subnets"></RRDataView>
                  <b-badge v-if="item.data in reserved_addrs_by_ip" variant="warning"
                           :title="$t('components.dns_record_type_card.address_is_reserved')">
                    {{ $t('components.dns_record_type_card.reserved') }}
                  </b-badge>
                  <b-badge href="#" @click="createRecord('AAAA', item.data, item.fqdn)"
                           v-if="bcd_has_v4 && bcd_has_v6 && record_type === 'A' && !fqdnHasAAAA(item.fqdn)"
                           variant="danger">{{ $t('components.dns_record_type_card.no_AAAA_record') }}
                  </b-badge>
                </b-td>
                <b-td>
                  <template v-if="item.ttl != null">{{ item.ttl }}{{ $t('system.second_time_s') }}</template>
                  <span v-else :title="$t('components.dns_record_type_card.inherited_from_zone')"
                        class="text-muted">{{ item.ttl_zone_default }}{{ $t('system.second_time_s') }}</span>
                  <span v-if="item.ttl_reset_days"
                        class="text-danger"><br/>{{
                      $tc('components.dns_record_type_card.reset_in_days', item.ttl_reset_days, {days: item.ttl_reset_days})
                    }}</span>
                  <span v-if="item.ttl_reset_date" class="text-danger"><br/>{{
                      $t('components.dns_record_type_card.reset_on_day', {day: item.ttl_reset_date})
                    }}</span>
                </b-td>
                <b-td>
                  <b-button-group>
                    <b-dropdown boundary="window"
                                :id="'button-edit-record-' +  item.fqdn + '_' + record_type + '_' + item.data"
                                @click="editRecord(item)" split variant="outline-primary">
                      <template #button-content>
                        <netvs-icon icon="edit"></netvs-icon>
                      </template>
                      <b-dropdown-item :disabled="!(item.fqdn in fqdns)"
                                       @click="edit_fqdn(fqdns[item.fqdn])">
                        {{ $t('components.dns_record_type_card.edit_fqdn') }}
                      </b-dropdown-item>
                    </b-dropdown>
                    <RecordDeleteButton :record="item"/>
                  </b-button-group>
                  <b-tooltip
                      :target="'button-edit-record-' +  item.fqdn + '_' + record_type + '_' + item.data"
                      triggers="hover"
                      variant="primary" placement="left">
                    {{ $t('components.dns_record_type_card.edit_record') }}
                  </b-tooltip>
                </b-td>
              </b-tr>
              <template
                  v-if="record_type === 'A' && !filter && sort_by === 'data' && fs_sliced_records.length - 1 === item_index && item.data in lowerIfAsc() && fs_sliced_records.length !== 1">
                <BCDFreeAddressBlock :key="'block_lower_' + item.data + '_' + j"
                                     v-for="(blk,j) in reverseIfDesc(create_block_for_last_ip_in_net(item.data))"
                                     :create_item_func="createRecord" :block="blk"
                                     :item="item" :index="j"/>
              </template>
            </template>
          </b-tbody>
        </b-table-simple>
        <Paginator :current-page="current_page" :per-page="per_page" @current-page="current_page = $event"
                   @per-page="per_page = $event" :total-rows="filtered_and_sorted_records.length"
                   v-if="loaded && filtered_and_sorted_records.length > 0"/>
      </b-collapse>
    </b-card-body>
    <CreateDNSRecord
        v-if="user_possible_record_types.includes(record_type)"
        :modal_id="`create_record-${record_type}`"
        :fixed_record_type="'type' in db_editor_presets?db_editor_presets.type:record_type"
        :loose_data="'data' in db_editor_presets?db_editor_presets.data:null"
        :fixed_fqdn="'fqdn' in db_editor_presets?db_editor_presets.fqdn:null"
    />

    <DBEditor :presets="db_editor_presets" :input_reducer="create_record_reducer"
              :modal_id="`update_record-${record_type}`"
              :object_title="object_title"
              :object_function="db_editor_function" object_fq_name="dns.record" :old_data="db_editor_old_data"
              :non_optionals_order="['fqdn', 'type', 'data', 'fqdn_description', 'target_is_singleton', 'target_is_reverse_unique']">
    </DBEditor>
  </b-card>
</template>

<script>
import FilterInput from '@/components/FilterInput'
import ipaddress from '@/util/ipaddress'
import RRDataView from '@/components/RRDataView'
import RecordInfo from '@/components/RecordInfo'
import apiutil from '@/util/apiutil'
import BCDFreeAddressBlock from '@/components/BCDFreeAddressBlock'
import DBEditor from '@/components/db-editor/APIObjectDBEditor.vue'
import CreateDNSRecord from '@/db-editors/CreateDNSRecord.vue'
import RecordDeleteButton from '@/components/RecordDeleteButton.vue'
import Paginator from '@/components/Paginator.vue'

export default {
  name: 'DNSRecordTypeCard',
  components: {
    Paginator,
    RecordDeleteButton,
    CreateDNSRecord,
    DBEditor,
    BCDFreeAddressBlock,
    RecordInfo,
    RRDataView,
    FilterInput
  },
  data() {
    return {
      filter: '',
      current_page: 1,
      per_page: 50,
      sort_dir: 'ascending',
      sort_by: ['A', 'AAAA'].includes(this.record_type) ? 'data' : 'fqdn',
      records_checked: [],
      asc_v4_blocklist: [],
      asc_v4_blocklist_by_lower: {},
      asc_v4_blocklist_by_upper: {},
      db_editor_presets: {},
      db_editor_old_data: {},
      create_record_reducer: undefined,
      object_title: null,
      db_editor_function: 'create',
    }
  },
  props: {
    record_type: {
      required: true
    },
    records: {
      required: true
    },
    loaded: {
      required: true
    },
    reserved_addrs_by_ip: {
      required: true,
      type: Object
    },
    full_edit_fqdn_reducer: {
      required: true,
      type: Object
    },
    record_types_by_name: {
      required: true,
      type: Object
    },
    records_by_fqdn: {
      required: true,
      type: Object
    },
    collapse_state: {
      required: true
    },
    edit_fqdn: {
      required: true,
      type: Function
    },
    user_possible_record_types: {
      required: true,
      type: Array
    },
    subnets: {
      required: true,
      type: Array
    },
    fqdns: {
      required: true
    },
    bcd_has_v4: {
      required: false,
      default() {
        return false
      }
    },
    bcd_has_v6: {
      required: false,
      default() {
        return false
      }
    },
    bcd: {
      required: true,
      type: Object
    },
  },
  mounted() {
    this.calculate_block_lists()
  },
  methods: {
    deleteRecord: RecordDeleteButton.methods.deleteRecord,
    canEnableBulkAction(rec_type) {
      let check_count = 0
      for (const b of this.records) {
        if (this.records_checked[rec_type + '_' + b.fqdn + '_' + b.data]) {
          check_count++
        }
      }
      return check_count > 0
    },
    filterFunc(item, term) {
      if (typeof term === 'string') {
        term = term.toLowerCase()
      }
      for (const v of Object.values(item)) {
        if (typeof v === 'string') {
          if (v.toLowerCase().includes(term)) {
            return true
          }
        }
      }
      return false
    },
    changeSort: function (col) {
      if (this.sort_by === col) {
        if (this.sort_dir === 'ascending') {
          this.sort_dir = 'descending'
        } else {
          this.sort_dir = 'ascending'
        }
      } else {
        this.sort_by = col
        this.sort_dir = 'ascending'
      }
    },
    exportCSV: function () {
      const items = this.filter ? this.records : this.fs_sliced_records

      let content = '"FQDN","FQDN_Description","Data","TTL"\n'
      items.forEach((item) => {
        content += `"${item.fqdn ? item.fqdn : ''}",`
        content += `"${item.fqdn_description ? item.fqdn_description : ''}",`
        content += `"${item.data ? item.data : ''}",`
        content += `"${item.ttl != null ? item.ttl : ''}"`
        content += '\n'
      })

      const blob = new Blob([content], {type: 'text/csv;charset=utf-8;'})

      const link = document.createElement('a')
      link.download = 'dns-export-' + this.bcd.name + '-' + this.record_type + '.csv'
      link.href = URL.createObjectURL(blob)
      link.click()
      URL.revokeObjectURL(link.href)
    },
    bulkDelete() {
      for (const b of this.records) {
        if (this.records_checked[this.record_type + '_' + b.fqdn + '_' + b.data]) {
          this.deleteRecord(b, false)
          this.$root.$set(this.records_checked, this.record_type + '_' + b.fqdn + '_' + b.data, false)
        }
      }
    },
    reverseIfDesc(arr) {
      return (this.sort_dir === 'ascending') ? arr : [...arr].reverse()
    },
    reverseIfAsc(arr) {
      return (this.sort_dir === 'descending') ? arr : [...arr].reverse()
    },
    upperIfAsc() {
      return (this.sort_dir === 'ascending') ? this.asc_v4_blocklist_by_upper : this.asc_v4_blocklist_by_lower
    },
    lowerIfAsc() {
      return (this.sort_dir === 'ascending') ? this.asc_v4_blocklist_by_lower : this.asc_v4_blocklist_by_upper
    },
    sync_collapse(visible) {
      this.$emit('update:collapse_state', visible)
    },
    fqdnHasAAAA(fqdn) {
      for (const r of this.records_by_fqdn[fqdn]) {
        if (r.type === 'AAAA') {
          return true
        }
      }
      return false
    },
    calculate_block_lists() {
      // Precalculate blocklists
      if (this.record_type !== 'A') {
        return
      }
      const working_arecs = this.records.slice()
      for (const subnet of this.subnets) {
        if (subnet.type !== '4') {
          continue
        }
        let last = ipaddress.ip_to_int(subnet.cidr.split('/')[0])
        const min = last
        const hard_maximum = ipaddress.ip_to_int(ipaddress.ip_net_get_last(subnet.cidr)) - 1
        let cur = null
        while (working_arecs.length > 0) {
          const r = working_arecs.shift()
          const cur_pre = ipaddress.ip_to_int(r.data)
          if (cur_pre > hard_maximum) {
            break
          }
          cur = cur_pre
          if (cur - last > 1) {
            const blk = {
              orphan: false,
              lower: ipaddress.int_to_ip(last),
              upper: ipaddress.int_to_ip(cur),
              subnet: subnet
            }
            const containing_blocks = []
            let last_chg_ip = last + 1
            let last_was_reserved = ipaddress.int_to_ip(last_chg_ip) in this.reserved_addrs_by_ip
            for (var i = 1; i < cur - last; i++) {
              if (ipaddress.int_to_ip(last + i) in this.reserved_addrs_by_ip) {
                if (!last_was_reserved) {
                  containing_blocks.push({
                    first: ipaddress.int_to_ip(last_chg_ip),
                    type: 'free',
                    space: (last + i) - last_chg_ip
                  })
                  last_was_reserved = true
                  last_chg_ip = (last + i)
                }
              } else {
                if (last_was_reserved) {
                  containing_blocks.push({
                    first: ipaddress.int_to_ip(last_chg_ip),
                    type: 'reserved',
                    space: (last + i) - last_chg_ip
                  })
                  last_was_reserved = false
                  last_chg_ip = last + i
                }
              }
            }
            if (last_was_reserved) {
              containing_blocks.push({
                first: ipaddress.int_to_ip(last_chg_ip),
                type: 'reserved',
                space: cur - last_chg_ip
              })
            } else {
              containing_blocks.push({
                first: ipaddress.int_to_ip(last_chg_ip),
                type: 'free',
                space: cur - last_chg_ip
              })
            }

            blk.containing_blocks = containing_blocks
            this.asc_v4_blocklist.push(blk)
          }
          last = cur
        }
        if (cur !== hard_maximum) {
          const orphan = cur === null
          if (orphan) {
            cur = min
          }
          // The last fucking block
          const blk = {
            orphan: orphan,
            lower: ipaddress.int_to_ip(cur),
            upper: ipaddress.int_to_ip(hard_maximum),
            subnet: subnet
          }
          const containing_blocks = []
          let last_chg_ip = cur + 1
          let last_was_reserved = ipaddress.int_to_ip(last_chg_ip) in this.reserved_addrs_by_ip
          for (var k = 1; k < hard_maximum - cur; k++) {
            if (ipaddress.int_to_ip(cur + k) in this.reserved_addrs_by_ip) {
              if (!last_was_reserved) {
                containing_blocks.push({
                  first: ipaddress.int_to_ip(last_chg_ip),
                  type: 'free',
                  space: (cur + k) - last_chg_ip
                })
                last_was_reserved = true
                last_chg_ip = (cur + k)
              }
            } else {
              if (last_was_reserved) {
                containing_blocks.push({
                  first: ipaddress.int_to_ip(last_chg_ip),
                  type: 'reserved',
                  space: (cur + k) - last_chg_ip
                })
                last_was_reserved = false
                last_chg_ip = cur + k
              }
            }
          }
          if (hard_maximum - cur > 1) {
            if (last_was_reserved) {
              containing_blocks.push({
                first: ipaddress.int_to_ip(last_chg_ip),
                type: 'reserved',
                space: hard_maximum + 1 - (last_chg_ip)
              })
            } else {
              containing_blocks.push({
                first: ipaddress.int_to_ip(last_chg_ip),
                type: 'free',
                space: hard_maximum + 1 - (last_chg_ip)
              })
            }
          }
          blk.containing_blocks = containing_blocks
          this.asc_v4_blocklist.push(blk)
        }
      }
      this.asc_v4_blocklist_by_lower = apiutil.dict_by_value_of_array(this.asc_v4_blocklist, 'lower')
      this.asc_v4_blocklist_by_upper = apiutil.dict_by_value_of_array(this.asc_v4_blocklist, 'upper')
    },
    editRecord: function (item) {
      this.db_editor_function = 'update'
      this.db_editor_old_data = item
      this.db_editor_presets = item
      this.object_title = item.type + '-' + this.$t('views.dnsvs.bcd_records.record_to_fqdn') + ' ' + item.fqdn
      this.create_record_reducer = {}
      this.create_record_reducer.fqdn = false
      this.create_record_reducer.type = false
      this.$root.$emit('bv::show::modal', `update_record-${this.record_type}`)
    },
    createRecord: function (record_type, data, fqdn) {
      this.db_editor_function = 'create'
      this.db_editor_presets = {
        type: record_type,
        data: data,
      }
      if (fqdn) {
        window.console.debug('Limiting fqdn to', fqdn)
        this.db_editor_presets.fqdn = fqdn
      }
      if (record_type === 'AAAA') {
        let sub = null
        let multi = false
        this.subnets.forEach((item) => {
          if (item.type === '6') {
            if (sub !== null) {
              multi = true
            }
            sub = item
          }
        })
        if (sub !== null && !multi) {
          const split = sub.cidr.split('/')
          this.db_editor_presets.data = split[0]
          if (split[1] === '64' && data) {
            this.db_editor_presets.data += data
          }
        } else if (multi) {
          this.db_editor_presets.data = ''
        }
      }
      if (record_type === 'A' && (!data)) {
        for (const bl of this.reverseIfAsc(this.asc_v4_blocklist)) {
          for (const b of bl.containing_blocks) {
            if (b.type === 'free') {
              this.db_editor_presets.data = b.first
              break
            }
          }
        }
      }
      this.$root.$emit('bv::show::modal', `create_record-${this.record_type}`)
    },
    create_block_for_last_ip_in_net(data) {
      let result = this.lowerIfAsc()[data].containing_blocks
      if (result.length === 0) {
        const upper = this.lowerIfAsc()[data].upper
        const type = upper in this.reserved_addrs_by_ip ? 'reserved' : 'free'
        result = [{first: upper, space: 1, type: type}]
      }
      return result
    }
  },
  computed: {
    filtered_records() {
      return this.records.filter(x => this.filterFunc(x, this.filter))
    },
    filtered_and_sorted_records() {
      let recs = [...this.filtered_records]
      if (this.filter) {
        recs = recs.filter(x => this.filterFunc(x, this.filter))
      }
      recs = recs.sort((a, b) => {
        if (this.record_type === 'A' && this.sort_by === 'data') {
          return ipaddress.ip_to_int(a.data) - ipaddress.ip_to_int(b.data)
        } else if (this.record_type === 'AAAA' && this.sort_by === 'data') {
          return ipaddress.compare_ipv6(a.data, b.data)
        } else if (this.sort_by === 'ttl') {
          return (a.ttl || a.ttl_zone_default) - (b.ttl || b.ttl_zone_default)
        } else {
          return (a[this.sort_by] || '').localeCompare((b[this.sort_by] || ''))
        }
      })
      if (this.sort_dir === 'descending') {
        return recs.reverse()
      }
      return recs
    },
    fs_sliced_records() {
      if (this.per_page <= 0) return this.filtered_and_sorted_records
      return this.filtered_and_sorted_records.slice(this.per_page * (this.current_page - 1), this.per_page * this.current_page)
    },
  },
  watch: {
    filter() {
      this.current_page = 1
    },
  }
}
</script>

<style scoped>
.list-group-item.active {
  color: white !important;
}

.collapse-icon {
  display: inline;
  vertical-align: center;
  float: right;

  transition: transform;
  transition-duration: 250ms;
}

.not-collapsed > .collapse-icon {
  transform: rotate(-180deg);
}

.bottom-space {
  padding-bottom: 200px;
}

.squish-enter-active, .squish-leave-active {
  transition: width 250ms, padding-left 250ms, padding-right 250ms;
}

.squish-enter, .squish-leave-to {
  width: 0;
  padding-right: 0;
  padding-left: 0;
}

.collapse-header {
  z-index: 69;
}

.record-table-header {
  vertical-align: middle;
}

.fqdn-description {
  display: inline-block;
  max-width: 350px;
}

</style>
