<template>
  <div class="netdoc">
    <b-card class="shadow mb-4" no-body>
      <b-card-header v-b-toggle:bcd-dd-collapse>
        <h5 class="d-inline">{{ $tc('views.nd.netdoc.dd', 2) }} (P-Ports)</h5>
        <netvs-icon class="collapse-icon" icon="collapse"></netvs-icon>
      </b-card-header>
      <b-card-body body-class="p-0">
        <b-collapse id="bcd-dd-collapse" :visible="true">
          <Loading :data="dd_p_port_of_bcd">
            <PaginatorTable v-if="dd_p_port_of_bcd.length > 0" :items="dd_p_port_of_bcd_styled"
                            :fields="bcd_dd_p_port_module_list_fields">
              <template v-slot:cell(bldg)="data">
                {{ data.item.bldg }}
              </template>
              <template v-slot:cell(is_defective)="data">
                <CheckMark :state="data.item.is_defective"/>
              </template>
              <template v-slot:cell(name)="data">
                <NETVSIcon v-b-tooltip.hover.v-danger :title="$t('views.nd.modules.port_defective')"
                           class="text-danger mr-1" icon="defective" v-if="data.item.is_defective"/>
                <RouterLink :to="'/netdoc/modules/' + pport2module[data.item.gpk] + '/p_port/' + data.item.gpk">
                  {{ data.item.name }}
                </RouterLink>
              </template>
              <template v-slot:cell(tagged)="data">
                <CheckMark :state="data.item.tagged"/>
              </template>
              <template v-slot:cell(protocol_hint)="data">
                {{ data.item.protocol }} ({{ data.item.mdl_type }})
              </template>
              <template v-slot:cell(actions)="data">
                <b-button-group>
                  <!-- currently not enabled as link to patch request not yet implemented -->
                  <!-- <b-button hidden :id="'button-edit-patch-' +  data.item.gpk" variant="outline-primary">
                    <NETVSIcon icon="edit"/>
                  </b-button>
                  <b-button hidden variant="outline-danger" :id="'button-unpatch-' + data.item.gpk">
                    <NETVSIcon icon="unlink"/>
                  </b-button> -->
                  <b-button variant="outline-primary" @click="editPPort(data.item)"
                            v-if="checkPermission('nd.rw:p_port')" :id="'edit-pport-btn-' + data.item.name">
                    <NETVSIcon icon="edit"></NETVSIcon>
                  </b-button>
                  <b-tooltip :target="'edit-pport-btn-' + data.item.name" triggers="hover" variant="primary" placement="left">
                    {{ $t('views.nd.bcd.edit_pport')}}
                  </b-tooltip>
                  <b-button @click="show_map_for_p_port(data.item)" v-if="$store.state.expert"
                            :id="'button-show-path-' +  data.item.gpk"
                            variant="outline-primary">
                    <NETVSIcon icon="graph"/>
                  </b-button>
                  <b-tooltip
                    :target="'button-show-path-' +  data.item.gpk"
                    triggers="hover"
                    variant="primary" placement="left">
                    <NETVSIcon icon="expert_feature"/>
                    {{ $t('views.nd.bcd.show_path') }}
                  </b-tooltip>
                </b-button-group>
                <b-tooltip
                    :target="'button-edit-patch-' +  data.item.gpk"
                    triggers="hover"
                    variant="primary" placement="left">
                  {{ $t('views.nd.bcd.request_patch') }}
                </b-tooltip>
                <b-tooltip
                    :target="'button-unpatch-' +  data.item.gpk"
                    triggers="hover"
                    variant="danger" placement="left">
                  {{ $t('views.nd.bcd.unpatch') }}
                </b-tooltip>
              </template>
            </PaginatorTable>
            <div class="w-100 text-center p-3" v-else>
              {{ $t('views.nd.bcd.no_dd_present') }}
            </div>
          </Loading>
        </b-collapse>
      </b-card-body>
    </b-card>
    <b-modal id="p-port-map-modal" :title="$t('views.nd.bcd.connection_path')" size="xl">
      <b-row>
        <b-col>
          <b-card class="shadow-sm network-card" no-body>
            <PPortNetwork v-if="map_p_port" :p_port="map_p_port"/>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <PatchRequestForm ref="prf"/>
    <PatchActionForm @ok="handleAction"/>
    <DBEditor modal_id="p_port_dbeditor" object_fq_name="nd.p_port" :object_function="object_function"
              :old_data="p_port_db_editor_old_data"
              :presets="p_port_db_editor_old_data"
              :non_optionals_order="['description', 'is_defective']"></DBEditor>
  </div>
</template>

<script>

import netdocService from '@/api-services/netdoc.service'
import Loading from '@/components/Loading.vue'
import NETVSIcon from '@/icons/NETVSIcon.vue'
import PatchRequestForm from '@/components/PatchRequestForm.vue'
import PatchActionForm from '@/components/PatchActionForm.vue'
import PPortNetwork from '@/components/nd/PPortNetwork.vue'
import CheckMark from '@/components/CheckMark.vue'
import PaginatorTable from '@/components/PaginatorTable.vue'
import DBEditor from '@/components/db-editor/APIObjectDBEditor.vue'
import apiutil from '@/util/apiutil'

export default {
  name: 'NetDocBCD',
  components: { DBEditor, PaginatorTable, CheckMark, PPortNetwork, PatchActionForm, PatchRequestForm, Loading, NETVSIcon},
  data() {
    return {
      bcd_name: undefined,
      bcd: undefined,
      dd_p_port_of_bcd: {},
      map_p_port: undefined,
      has_v4: false,
      has_v6: false,
      pport2module: {},
      p_port_db_editor_old_data: undefined,
    }
  },
  methods: {
    show_map_for_p_port(p_port) {
      this.map_p_port = p_port
      this.$bvModal.show('p-port-map-modal')
    },
    editPPort(item) {
      this.p_port_db_editor_old_data = item
      this.object_function = 'update'
      this.$root.$emit('bv::show::modal', 'p_port_dbeditor')
    },
    checkPermission(perm) {
      return apiutil.checkPermission(this.$store.state, perm)
    },
    handleAction(payload) {
      this.$refs.prf.handleAction(payload)
    }
  },
  async created() {
    this.bcd_name = this.$route.params.bcd_name

    const bcd_result = await netdocService.get_bcd_subnets_by_name(this.$store.state, this.bcd_name)
    this.bcd = bcd_result.data.bcd_list[0]
    this.$store.commit('setNavigationRefreshHandle', {gpk: this.bcd.gpk, objType: 'nd.bcd'})

    this.has_v4 = this.has_v6 = false
    for (const sub of bcd_result.data.ip_subnet_list) {
      this.has_v4 = this.has_v4 || sub.type === '4'
      this.has_v6 = this.has_v6 || sub.type === '6'
    }

    const r = await netdocService.get_dd_by_bcd_new(this.$store.state, this.bcd_name)
    this.dd_p_port_of_bcd = r.data.filtered_remote_p_port_list
    for (const i in this.dd_p_port_of_bcd) {
      this.dd_p_port_of_bcd[i].tagged = r.data.tagged_p_port_list.some(p => p.dest_connected_gfk === this.dd_p_port_of_bcd[i].gpk)
    }
    for (const p of r.data.dd_module_list) {
      this.dd_p_port_of_bcd.filter(p2 => {
        return p2.mdl_bldg === p.bldg && p2.mdl_room === p.room && p2.mdl_fq_name === p.fq_name
      }).forEach(p2 => {
        this.pport2module[p2.gpk] = p.gpk
      })
    }
    console.log('P2M', this.pport2module)
  },
  computed: {
    dd_p_port_of_bcd_styled() {
      return this.dd_p_port_of_bcd.map(p => {
        if (p.is_defective) {
          p._rowVariant = 'danger'
        }
        return p
      })
    },
    bcd_dd_p_port_module_list_fields() {
      let result = [
        {
          label: this.$t('components.patch_request_form.building'),
          key: 'mdl_bldg',
          sortable: true
        },
        {
          label: this.$t('components.patch_request_form.room'),
          key: 'mdl_room',
          sortable: true
        },
        {
          label: this.$t('views.nd.netdoc.module'),
          key: 'mdl_fq_name',
          sortable: true
        },
        {
          label: this.$t('views.nd.netdoc.p_port'),
          key: 'name',
          sortable: true
        },
        {
          label: this.$t('system.description'),
          key: 'description',
          sortable: false
        }
      ]
      if (this.$store.state.expert) {
        result = result.concat([
          {
            label: this.$t('views.nd.bcd.protocol'),
            key: 'protocol_hint',
            sortable: true
          }
        ])
      }
      result = result.concat([
        {
          label: this.$t('views.nd.bcd.tagged'),
          key: 'tagged',
          sortable: true
        }
      ])
      if (this.$store.state.developer) {
        result = result.concat([
          {
            label: this.$t('system.gpk'),
            key: 'gpk',
            sortable: true
          }
        ])
      }
      result = result.concat([
        {
          label: this.$tc('system.action', 2),
          key: 'actions'
        }
      ])
      return result
    }
  }
}
</script>

<style scoped>
.collapse-icon {
  display: inline;
  vertical-align: center;
  float: right;

  transition: transform;
  transition-duration: 250ms;
}

.not-collapsed > .collapse-icon {
  transform: rotate(-180deg);
}

.collapse-header {
  z-index: 69;
}
</style>
