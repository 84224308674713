var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Loading',{attrs:{"data":[_vm.bcd]}},[(_vm.bcd)?_c('b-table-simple',{staticClass:"m-0",attrs:{"responsive":"","striped":""}},[_c('b-tr',[_c('b-th',[_vm._v(_vm._s(_vm.$t('views.dnsvs.bcd_records.supervisors_admins')))]),_c('b-td',[(_vm.ous.length > 0)?[_vm._v(" "+_vm._s(_vm.$t('views.dnsvs.bcd_records.by_higher_level_OU'))+" "),_c('ul',_vm._l((_vm.parent_ou_tree_roots),function(pou){return _c('OUMgrTreeEntry',{key:pou.short_name,attrs:{"ou":pou,"ous_by_parent":_vm.ous_by_parent,"parent_oe2mgr":_vm.parent_oe2mgr,"parent_oe_mgr":_vm.parent_oe_mgr,"direct_bcd2ous_by_ou":_vm.direct_bcd2ous_by_ou,"current_bcd":_vm.bcd}})}),1)]:_c('p',{staticClass:"font-italic"},[_vm._v(" "+_vm._s(_vm.$t('views.dnsvs.bcd_records.no_OU_assigned'))+" ")]),(_vm.bcd_groups.length > 0)?[_vm._v(" "+_vm._s(_vm.$t('views.dnsvs.bcd_records.through_group_assignments'))+" "),_c('ul',_vm._l((_vm.bcd_groups),function(grp){return _c('li',{key:grp.group_name},[_c('b',{attrs:{"title":grp.group_name in _vm.groups?_vm.groups[grp.group_name].description:''}},[_c('b-link',{attrs:{"to":'/cntl/groups/'+grp.group_name}},[_vm._v(_vm._s(grp.group_name))])],1),(grp.group_name in _vm.group2mgrs && _vm.group2mgr_emails(grp.group_name).length > 0)?_c('b-link',{staticClass:"ml-1",attrs:{"href":`mailto:${_vm.group2mgr_emails(grp.group_name).join(';')}?subject=${
                                    _vm.$t('views.dnsvs.bcd_records.group_mail_template',
                                    {group_name: grp.group_name, name: _vm.bcd.name})
                                  }`}},[_c('netvs-icon',{attrs:{"icon":"mail"}})],1):_vm._e(),(grp.group_name in _vm.groups && _vm.groups[grp.group_name].is_admin)?_c('b-badge',{staticClass:"ml-1",attrs:{"variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t('system.admin'))+" ")]):_vm._e(),(grp.group_name in _vm.groups && _vm.groups[grp.group_name].is_own)?_c('b-badge',{staticClass:"ml-1",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('system.member'))+" ")]):_vm._e(),(_vm.groups[grp.group_name] && _vm.groups[grp.group_name].description)?_c('b-badge',{staticClass:"ml-1",attrs:{"variant":"warning"}},[_c('span',{attrs:{"title":_vm.$t('system.description')}},[_c('NetvsIcon',{staticClass:"mr-1",attrs:{"icon":"info"}}),_vm._v(_vm._s(_vm.groups[grp.group_name].description))],1)]):_vm._e(),(grp.group_name in _vm.group2mgrs)?_c('ul',_vm._l((_vm.group2mgrs[grp.group_name]),function(mgr){return _c('li',{key:mgr.mgr_login_name},[_c('MgrLine',{attrs:{"mgr":_vm.group_mgrs[mgr.mgr_login_name]}})],1)}),0):_vm._e()],1)}),0)]:_c('p',{staticClass:"font-italic"},[_vm._v(" "+_vm._s(_vm.$t('views.dnsvs.bcd_records.no_group_assigned'))+" ")])],2)],1),_c('b-tr',[_c('b-th',[_vm._v(_vm._s(_vm.$tc('system.domain', 2)))]),_c('b-td',[_vm._v(" "+_vm._s(_vm.$t('views.dnsvs.bcd_records.through_group_assignments'))+" "),_c('ul',_vm._l((_vm.bcd_groups),function(grp){return _c('li',{key:grp.group_name},[_c('b',{attrs:{"title":grp.group_name in _vm.groups?_vm.groups[grp.group_name].description:''}},[_c('b-link',{attrs:{"to":'/cntl/groups/'+grp.group_name}},[_vm._v(_vm._s(grp.group_name))])],1),(grp.group_name in _vm.group2mgrs && _vm.group2mgr_emails(grp.group_name).length > 0)?_c('b-link',{staticClass:"ml-1",attrs:{"href":`mailto:${_vm.group2mgr_emails(grp.group_name).join(';')}?subject=${
                                    _vm.$t('views.dnsvs.bcd_records.group_mail_template',
                                    {group_name: grp.group_name, name: _vm.bcd.name})
                                  }`}},[_c('netvs-icon',{attrs:{"icon":"mail"}})],1):_vm._e(),(grp.group_name in _vm.groups && _vm.groups[grp.group_name].is_admin)?_c('b-badge',{staticClass:"ml-1",attrs:{"variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t('system.admin'))+" ")]):_vm._e(),(grp.group_name in _vm.groups && _vm.groups[grp.group_name].is_own)?_c('b-badge',{staticClass:"ml-1",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t('system.member'))+" ")]):_vm._e(),(_vm.groups[grp.group_name] && _vm.groups[grp.group_name].description)?_c('b-badge',{staticClass:"ml-1",attrs:{"variant":"warning"}},[_c('span',{attrs:{"title":_vm.$t('system.description')}},[_c('NetvsIcon',{staticClass:"mr-1",attrs:{"icon":"info"}}),_vm._v(_vm._s(_vm.groups[grp.group_name].description))],1)]):_vm._e(),_c('ul',_vm._l((_vm.fqdns2group[grp.group_name]),function(fqdn){return _c('li',{key:fqdn.fqdn_value},[_c('b-link',{attrs:{"to":'/dnsvs/fqdns/'+fqdn.fqdn_value}},[_vm._v(_vm._s(fqdn.fqdn_value))])],1)}),0)],1)}),0)])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }